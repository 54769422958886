@use "sass:math";
@mixin calcLetterSpacing($fontSize, $percent) {
  // letter-spacing: ($fontSize * $percent/100) + px;
  letter-spacing: math.div($fontSize * $percent, 100) + px;
}

.button-text {
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  @include calcLetterSpacing(16, 5);
}
.bold-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 19.5px;
  @include calcLetterSpacing(16, 0);
}
.large-title-text {
  font-weight: 800;
  font-size: 64px;
  line-height: 78px;
  @include calcLetterSpacing(64, -2);
}
.heading1-text {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  @include calcLetterSpacing(48, -2);
}
.heading2-text {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  @include calcLetterSpacing(32, -2);
}
.heading3-text {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  @include calcLetterSpacing(28, 0);
}
.body-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  @include calcLetterSpacing(16, 0);
}
.link-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  @include calcLetterSpacing(16, 0);
}
.small-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  @include calcLetterSpacing(14, 0);
}
