@import "../../styles/styles.scss";

.navbar {
  height: 120px;
  padding: 26.5px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: transparent;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  // 576px window width and more
  @include respond(mobile) {
    background-color: transparent;
    // padding: 0;
    // padding: 8px 0;
    height: 60px;
    padding: 8px 0;
  }

  .nav-burger {
    display: none;
    width: 44px;
    height: 44px;
    margin-right: 40px;
    @include respond(mobile) {
      display: block;
      margin-right: 20px;
    }
    &-list {
      display: none;
      width: 100%;
      padding: 0;
      background-color: $primary1;
      position: absolute;
      top: 84px;
      left: 0;
      > li {
        display: none;
      }
    }
    .icon-active {
      filter: brightness(0) saturate(100%) invert(38%) sepia(21%)
        saturate(3000%) hue-rotate(138deg) brightness(93%) contrast(101%);
    }
  }
  .nav-burger-list.display {
    display: block;
    padding: 0 28px;
    > li {
      height: 84px;

      background-color: $primary1;

      display: flex;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px solid $primary1Tint1;
      }
      > a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  .logo-2 {
    width: 198px;
    height: 68px;
    object-fit: cover;
    margin-left: 28px;
  }

  &.nav-show {
    background-color: $primary1 !important;
    height: 84px !important;
    > img {
      width: 198px !important;
      height: 68px !important;
      object-fit: cover !important;
      margin-left: 28px !important;
    }
  }

  &.active {
    background-color: $neutral3;
    height: 80px;
    // padding: 0 30px;
    padding: 0 40px;

    // padding-left: 40px;
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.08);
    @include respond(mobile) {
      height: 60px;
      padding: 0 8px;
      .nav-burger-list {
        top: 64px;
      }
      .logo-2 {
        width: 128px;
        height: 44px;
        object-fit: cover;
      }
    }
  }

  .logo {
    width: 194px;
    height: 67px;
    @include respond(mobile) {
      width: 128px;
      height: 44px;
      object-fit: cover;
      margin-left: 20px;
    }
  }
  .downloadBtn {
    padding: 16px 32px;
  }

  .nav-items {
    display: flex;
    align-items: center;
    // margin-right: 32px;
    &:last-child {
      // margin: 0 32px;
    }
    @include respond(mobile) {
      display: none;
    }
  }
  .nav-item {
    padding: 16px 32px;
    display: block;
    text-decoration: none;
    color: $neutral3;
    cursor: pointer;
    transition: 0.5s all ease;
    // &:hover > span {
    //   color: $primary1Tint4;
    //   transition: 0.5s all ease;
    //   // padding-top: 8px;
    //   &::after {
    //     display: block;
    //     position: relative;
    //     top: 8px;
    //     border-bottom: 2px solid $primary1Tint4;
    //     content: "";
    //   }
    // }
    &.scrolled {
      color: $neutral1;
    }
    &-active > span {
      color: $primary1Tint4;
      // padding-top: 8px;
      &::after {
        display: block;
        position: relative;
        top: 8px;
        border-bottom: 2px solid $primary1Tint4;
        content: "";
      }
      // border-bottom: 2px solid $primary1Tint4;
    }
  }
  // .nav-item-scrolled {
  // }
  // @include respond(mobile) {
  //   .navbar .active {
  //     height: 80px;
  //   }
  // }
  .burger-download {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: $primary1Tint4;
    > p {
      margin: 40px 0;
    }
  }
}

@media screen and (max-width: 900px) {
  .navbar {
    padding: 26.5px 20px;
    .nav-items:last-child {
      margin: 0;
    }
    .nav-item {
      padding: 16px 16px;
    }
  }
}

@media screen and (max-width: 769px) {
  .navbar {
    &.nav-show {
      padding: 0;
    }
  }
}
