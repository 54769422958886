@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: Montserrat, sans-serif !important;
}

.container {
  padding: 0 240px;

  @include respond(laptop) {
    padding: 0 140px;
  }
  @include respond(tablet) {
    padding: 0 60px;
  }
  @include respond(mobile) {
    padding: 0 28px;
    // padding: 0;
  }
}

// margin bottom
.mb-8 {
  margin-bottom: 8px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-32 {
  margin-bottom: 32px;
}
// margin left
.ml-8 {
  margin-left: 8px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-32 {
  margin-left: 32px;
}
// margin right
.mr-8 {
  margin-right: 8px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-32 {
  margin-right: 32px;
}

.btn {
  padding: 16px 32px;
  border-radius: 8px;
  // @include buttonFont;
  text-decoration: none;
  &Filled {
    background-color: $primary1;
    color: $neutral3;
    &:hover {
      background-color: $primary1Tint1;
    }
  }
  &FilledWhite {
    background-color: $neutral3;
    color: $primary1;
    &:hover {
      color: primary1Tint1;
    }
  }
  &-outline {
    background-color: transparent;
    border: 2px solid $primary1Tint3;
    color: $primary1Tint3;
    &:hover {
      // background-color: $primary1Tint4;
      border: 2px solid $primary1Tint4;
    }
  }
  &-outline-white {
    background-color: transparent;
    border: 2px solid $neutral3;
    color: $neutral3;
    &:hover {
      background-color: $primary3;
    }
  }
}
