// Primary color
$primary1: #00837b;
$primary2: #0a2240;
$primary3: #f1f2f2;

$primary1Tint1: #009c92;
$primary1Tint2: #00b5aa;
$primary1Tint3: #00cfc2;
$primary1Tint4: #00e8da;
// Secondary color
$secondary1: #e07e37;
$secondary2: #77ba6a;
$secondary3: #e2e1e1;

// Neutral
$neutral1: #404040;
$neutral2: #d0d0d0;
$neutral3: #ffffff;
// Gradient
$gradient1: linear-gradient(231.69deg, #00e8da 0%, #00837b 100%);
$gradient2: linear-gradient(231.69deg, #fff080 0%, #e07e37 100%);
