@import "../../styles/styles.scss";
footer {
  @include respond(mobile) {
    padding-top: 20px;
  }
  .footer-wrapper {
    height: 240px;
    @include respond(mobile) {
      height: auto;
    }

    .footer-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 160px;
        min-width: 160px;
        height: 160px;
        @include respond(mobile) {
        }
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      .info {
        display: flex;
        @include respond(mobile) {
          flex-direction: column;
          margin-top: 32px;
          margin-bottom: 28px;
          margin-left: 31px;
        }
        .name {
          text-transform: uppercase;
          margin-right: 64px;
          color: $primary1;
          max-width: 160px;
          @include respond(mobile) {
            margin-right: 0;
          }
        }
        .address {
          max-width: 295px;

          color: $neutral1;
          span {
            display: block;
            line-height: 28px;
          }
        }
      }
    }

    .ruler {
      height: 1px;
      width: 100%;
      background-color: $secondary3;
      margin-top: 10px;
    }

    .footer-bottom {
      text-align: center;
      padding: 19px 0;
    }
  }
}
