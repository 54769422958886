@mixin respond($breakpoint) {
  @if $breakpoint == laptop {
    @media only screen and (max-width: 1600px) {
      @content;
    }
  }
  @if $breakpoint==tablet {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 1025px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}
