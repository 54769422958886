@import "../../styles/styles.scss";
:root {
  --animate-duration: 2000ms;
  --animate-delay: 0s;
  visibility: visible;
}

.jack-header {
  position: relative;
  width: 100%;
  height: 100%;
  // height: 100vh;
  top: -30px;

  .header-img-wrapper {
    position: relative;
    max-width: 800px;

    & > img {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: auto;
    }
  }

  .back {
    left: 0;
    top: 0;

    transform-origin: 50% 65%;
    transition: 1s all linear;
    transform: scale(0.5);
    opacity: 0;

    visibility: hidden;
  }
  .back-inview {
    transform: scale(1);
    transition: 1s all linear;

    opacity: 1;
  }
  .main {
    transition: 0.8s all ease-out;
    opacity: 0;
    transform: translate(0px, 100px);
    &.main-inview {
      transition: 0.8 all ease-out;
      opacity: 1;

      transform: translate(0, 0);
      // left: 150px;
      // top: 120px;
    }
  }

  .front {
    left: 0;
    top: 0;
    transition: 1s all linear;
    // transform: scale(1.5);
    overflow: hidden;
    opacity: 0;
    visibility: hidden;

    transform: translate(-50px, 100px);
  }
  .front-inview {
    // transform: scale(1);
    transition: 1s all linear;
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 1025px) {
  .jack-header {
    min-height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .jack-header {
    // min-height: 300px;

    // .back-inview {
    //   top: 30px;
    //   left: 0px;
    // }
    // .front-inview {
    //   top: 40px;
    //   left: 0px;
    // }
    // .main {
    //   transform: translate(60px, 150px);
    //   &.main-inview {
    //     top: -30px;
    //     left: -90px;
    //     top: 95px;
    //     left: 60px;
    //   }
    // }
  }
}
