@import "../../styles/styles.scss";
.home-page {
  .header {
    // height: 200vh;
    // overflow: hidden;
    position: relative;
    height: 100vh;
    background: transparent url(../../assets/background.png) no-repeat center;
    background-size: cover;
    padding-top: 120px;
    padding: 120px 160px 0 240px;

    @include respond(laptop) {
      // padding: 80px 40px 0px 120px;
      padding-left: 120px;
      padding-right: 120px;
      // overflow: hidden;
      // height: auto;
    }
    &-content {
      // padding: 0 240px;
      position: relative;

      display: flex;
      flex-direction: row;
      // justify-content: space-between;
    }
    &-left {
      position: relative;
      top: 120px;
      display: flex;
      flex-direction: column;
      max-width: 635px;
      margin-right: 40px;
      min-width: 300px;
      // @include largeTitle;
      flex: 1;
      color: $neutral3;
      @include respond(laptop) {
        max-width: 500px;
        // width: 100%;
        font-size: 48px;
      }

      .header-btn {
        align-self: flex-start;
        margin-top: 32px;
      }
    }
    &-sub-title {
      @include flex-center-vert;
      // @include body;
      color: $neutral2;
      & > img {
        width: 80px;
        height: 80px;
        margin-right: 24px;
      }
    }
    &-right {
      position: relative;
      // background-color: yellow;
      // width: 800px;
      // height: 800px;
      min-width: 0;
      width: 800px;
      // flex: 1;
    }
    .header-buffer {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .line {
    height: 2px;
    width: 80px;
    margin: 16px 0 32px 0;
    background: linear-gradient(66.2deg, #00e8da 15.3%, #00837b 84.26%);
  }
  // section app
  .app-section {
    margin-bottom: 143px;
    @include respond(mobile) {
      display: none;
    }
  }
  .app-content {
    display: flex;

    &-left {
      padding-top: 160px;

      padding-left: 230px;
      padding-right: 100px;
      > img {
        object-fit: cover;
        width: 410px;
        height: 496px;
        // aniamtion
        opacity: 0;
        transform: scale(0.5);
      }
      .app-inview {
        opacity: 1;
        transition: 1s all ease;
        transform: scale(1);
      }
    }
    &-right {
      padding-top: 240px;
      transform: translateX(-50px);
      transition: 0.5s all linear;
      opacity: 0;
      & .title {
        color: $primary1;
      }
      &.inview {
        transform: translateX(0);
        transition: 0.5s all linear;
        opacity: 1;
      }
      p {
      }
    }
  }

  // guide-section
  .guide-section {
    padding-bottom: 163px;
    // background-image: url("../../assets/bingo/guide/background.png");
    background-position-x: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    .title {
      text-align: center;
      text-transform: uppercase;
      color: $primary1;
      padding-bottom: 60px;
      padding-top: 40px;
      transform: translateY(50px);
      opacity: 0;
      transition: 0.3s linear;
      &.title-inview {
        transform: translateY(0);
        opacity: 1;
        transition: 0.3s linear;
      }
    }
    .guide-content {
      padding-left: 30px;
      &-top {
        display: flex;
        justify-content: center;
        margin-left: -88px;
        transform: translateY(50px);

        opacity: 0;
        &.guide-inview {
          transform: translateY(0);
          opacity: 1;
          transition: 0.5s linear;
        }
        a {
          color: $primary1;
        }
        .card {
          display: flex;
          flex-direction: column;
          width: calc(33% - 88px);
          margin-left: 88px;
          &-title {
            color: $secondary1;

            &::after {
              position: relative;
              content: "";
              width: 24px;
              height: 2px;
              margin: 8px 0;
              background: $secondary1;
              display: block;
            }
          }
          &-content {
            margin-bottom: 32px;
            flex: 1;
            color: $neutral1;
          }

          .card-image {
            padding: 52px 16px;
            background: #fff;
            box-shadow: 0px 16px 64px rgba(0, 128, 120, 0.2);
            border-radius: 24px;
            height: 304px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            > img {
              max-width: 100%;
              height: 100%;
              width: auto;
              // max-height: 200px;
              object-fit: contain;
            }

            &-2 {
              padding: 32px 16px;
              // & img:first-child {
              //   width: 264px;
              //   height: 72px;
              // }
              // & img:nth-child(2) {
              //   padding: 16px 0;
              // }
              // & img:nth-child(3) {
              //   margin: 0 8px;
              // }
            }
          }
        }
      }
      .ruler {
        height: 1px;
        width: 100%;
        background-color: $secondary3;
        margin: 105px 0 70px 0;
      }

      .subtitle {
        display: flex;
        align-items: center;
        color: $secondary2;
        position: relative;
        margin-bottom: 16px;
        > p::after {
          position: relative;
          content: "";
          width: 24px;
          height: 2px;
          margin: 8px 0;
          background: $secondary2;
          display: block;
        }
        img {
          margin-left: 16px;
          margin-bottom: 18px;
        }
      }

      &-bottom {
        display: flex;
        .card {
          width: 400px;

          .content {
            color: $neutral1;
          }
        }
        .image-wrapper {
          width: 342px;
          height: 200px;
          margin-left: 100px;
          & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  .rule-section {
    // height: 100vh;
    background: radial-gradient(
      100% 100% at 100% 100%,
      #00e8da 0%,
      #00837b 100%
    );

    .buffer {
      width: 100%;
    }
    .rule-content {
      padding-top: 40px;
      padding-bottom: 100px;
      display: flex;
      justify-content: space-between;

      flex-wrap: wrap;
      color: $neutral3;

      &-left {
        // margin-right: 182px;
        width: calc(100% - 182px);
        transform: translateX(-30px);
        opacity: 0;
        flex: 1;
        margin-right: 182px;
        .rule-download {
          display: flex;
          align-items: center;
          padding-top: 48px;
          color: $primary1Tint4;
          cursor: pointer;
          width: 300px;

          > img {
            padding-right: 8px;
          }
        }
        &.rule-inview {
          transform: translateX(0);
          opacity: 1;
          transition: 0.3s linear;
        }
      }
      .rule-title {
        color: white;
        margin-bottom: 48px;
        text-transform: uppercase;
      }
      &-right {
        // width: 680px;
        // max-width: 600px;
        width: 600px;
        // height: auto;
        height: 100%;
        transform: translateY(50px);
        transition: 0.5s linear;
        opacity: 0;
        &.rule-right-inview {
          transform: translateY(0);
          transition: 0.5s linear;
          opacity: 1;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
          padding: 64px;
          padding-right: 100px;
        }
        .prize-table {
          // width: 680px;
          width: 100%;
          color: red;
          // height: 400px;
          object-fit: contain;
          // background: bisque;
          .divider {
            margin: 16px 0 32px 0;
            width: 100%;
            height: 1px;
            background: $neutral2;
          }
          > img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
          .tabs {
            display: flex;
            align-items: center;
            justify-content: center;
            .tab {
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 29px;
              letter-spacing: -0.02em;

              background: white;
              border: 0;
              outline: 0;
              cursor: pointer;
              position: relative;
              &:first-child {
                padding-right: 32px;
              }

              &:last-child {
                padding-left: 32px;
              }

              color: $neutral2;
              &:not(:last-child)::after {
                content: "";
                display: block;
                height: 16px;
                width: 2px;
                background: $neutral2;

                position: absolute;

                top: 8px;
                right: 0;
              }
            }
            .tab-active {
              color: $primary1;
            }
          }
        }
      }
    }
  }
  .cta-section {
    background: $primary2;
    color: $neutral3;
    height: 160px;
    padding: 56px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .cta-content {
      display: flex;
      align-items: center;
      @include respond(mobile) {
        flex-direction: column;
      }
      p {
        max-width: 420px;
        margin-right: 64px;
      }
      .cta-download {
        display: none;
      }
    }
  }
  .mb-download {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .home-page {
    .header {
      .header-content {
        .header-right {
          top: 40px;
          width: 600px;
        }
      }
    }
    .app-content {
      &-left {
        padding-left: 0;
      }
    }
    .rule-section {
      .rule-content {
        &-left {
          margin-right: 60px;
        }
        &-right {
          width: 500px;
        }
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .home-page {
    .header {
      .header-content {
        .header-left {
          font-size: 36px;
        }
        .header-right {
          top: 40px;
          width: 550px;
        }
      }
    }
  }
}

@media screen and(max-width: 1025px) {
  .home-page {
    .header {
      height: 1000px;
      // overflow: hidden;
      // top: 100px;
      .header-content {
        flex-direction: column;
        align-items: center;
        .header-left {
          margin-right: 0;
          text-align: center;
          flex-grow: 0;

          & > div,
          a {
            display: none;
          }
        }
        .header-right {
          top: 180px;
          padding-bottom: 0;
          height: 500px;
        }
      }
    }
    .app-section {
      display: none;
    }

    .guide-section {
      .guide-content {
        padding-left: 0;
        // margin-left: 28px;
        // margin-right: 28px;

        &-top {
          margin: 0;
          overflow-x: auto;
          margin: 0 -28px;
          padding-right: 28px;
          justify-content: flex-start;
          &::-webkit-scrollbar {
            width: 0;
          }

          .card {
            min-width: 400px;
            padding-left: 28px;
            // margin-right: 28px;
            margin-left: 0;
            margin-bottom: 50px;
            .card-image {
              height: 295px;
              box-shadow: 0px 16px 40px rgb(0 128 120 / 16%);
            }
          }
        }
        .ruler {
          margin: 0 0 50px 0;
        }

        .subtitle {
          width: 230px;

          display: flex;
          align-items: center;
          color: $secondary2;
          position: relative;
          margin-bottom: 16px;
          p {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            padding-right: 16px;
          }

          img {
            margin: 0;
            width: 48px;
            height: 48px;
          }
        }
        &-bottom {
          // flex-direction: column;
          flex-wrap: wrap;
          .card {
            width: auto;
          }
          .image-wrapper {
            flex: 1;
            object-fit: cover;
            margin: 0;
            margin-top: 16px;
            width: auto;
            height: auto;
            img {
              height: auto;
            }
            &:last-child {
              margin-left: 16px;
            }
          }
        }
      }
    }

    .rule-section {
      .rule-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-left {
          width: auto;
          margin-right: 0;
        }
        &-right {
          flex: 1;
          margin-top: 48px;
        }
      }
    }
    .mb-download {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 80px 0;
      > img {
        width: 300px;
        height: auto;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .home-page {
    .header {
      height: 800px;
      .header-content {
        .header-left {
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 34px;
          margin-right: 0;
          width: 100%;
          text-align: center;

          display: flex;
          align-items: center;
          justify-content: center;

          top: 0;

          .header-btn {
            display: none;
          }
          p {
            width: 320px;
            text-align: center;
          }
        }
        .header-right {
          top: 40px;
          height: 500px;
          padding-bottom: 0;
          width: 100%;
          min-width: 400px;
        }
      }
    }
    .rule-section {
      .rule-content {
        &-left {
          width: auto;
          .rule-title {
          }
        }
        &-right {
          height: auto;
          min-width: 0;
          max-width: 500px;
          width: auto;
        }
      }
    }
    .cta-section {
      .cta-content {
        margin: 0;
        justify-content: center;
        p {
          margin-right: 0;
        }
        a {
          margin-right: 0;
          > img {
            margin-right: 0;
          }
        }
      }
    }
    .mb-download {
      margin: 50px 0;
      // margin-bottom: 34px;
      > img {
        width: 160px;
        height: auto;
      }
    }
  }
}

@media screen and(max-width: 480px) {
  .home-page {
    .header {
      padding: 0 24px 0 24px;
      height: 650px;
      .header-content {
        flex-direction: column;
        .header-left {
          top: 120px;
        }
        .header-sub-title {
          display: none;
          & > img {
          }
        }
        .line {
          display: none;
        }
        .header-right {
          min-width: calc(100% - 680px);
          top: 120px;
          padding-bottom: 0;
        }
      }
    }
    .app-section {
      display: none;
    }
    .guide-section {
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      .title {
        align-self: center;
        width: 188px;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
      }
      .guide-content {
        padding-left: 0;
        // margin-left: 28px;
        // margin-right: 28px;

        &-top {
          margin: 0;
          justify-content: flex-start;
          overflow-x: auto;
          margin: 0 -28px;
          padding-right: 28px;

          &::-webkit-scrollbar {
            width: 0;
          }
          .card {
            min-width: 300px;
            padding-left: 28px;
            // margin-right: 28px;
            margin-left: 0;
            margin-bottom: 50px;
            .card-image {
              height: 295px;
              box-shadow: 0px 16px 40px rgb(0 128 120 / 16%);
            }
          }
        }
        .ruler {
          margin: 0 0 50px 0;
        }

        .subtitle {
          width: 200px;

          display: flex;
          align-items: center;
          color: $secondary2;
          position: relative;
          margin-bottom: 16px;
          p {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            padding-right: 16px;
          }

          img {
            margin: 0;
            width: 48px;
            height: 48px;
          }
        }
        &-bottom {
          // flex-direction: column;
          flex-wrap: wrap;
          .card {
            width: auto;
          }
          .image-wrapper {
            flex: 1;
            object-fit: cover;
            margin: 0;
            margin-top: 16px;
            width: auto;
            height: auto;
            img {
              height: auto;
            }
            &:last-child {
              margin-left: 16px;
            }
          }
        }
      }
    }
    .rule-section {
      height: 600px;
      overflow-y: scroll;
      // position: fixed;
      .rule-content {
        flex-direction: column;
        padding-bottom: 70px;
        &-left {
          width: auto;
          .rule-title {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
          }
        }
        &-right {
          width: auto;
          height: auto;
          margin-top: 48px;

          &.rule-right-inview {
            padding: 32px;
            padding-right: 64px;
          }
        }
      }
    }
    .cta-section {
      padding: 38px 0;
      height: auto;
      .cta-content {
        margin: 0;
        margin-bottom: -16px;
        p {
          margin-right: 0;
          margin-bottom: 16px;
        }
        a {
          display: none;
          margin-right: 0;
          margin-bottom: 16px;
        }
        .cta-download {
          display: block !important;
        }
      }
    }
    .mb-download {
      margin: 30px 0;
      // margin-bottom: 34px;
      > img {
        width: 160px;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 374px) {
  .home-page {
    .container {
      padding: 0;
    }
    .rule-section {
      height: 600px;
      overflow-y: scroll;
      // position: fixed;
      .rule-content {
        flex-direction: column;
        padding-bottom: 70px;
        &-left {
          width: auto;
          transform: translateX(0px);
          padding: 0px 10px;
          opacity: 1;
          .rule-title {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
          }
        }
        &-right {
          width: auto;
          height: auto;
          margin-top: 0px;
          opacity: 1;
          width: 290px;
          background-color: #fff;
          border-radius: 8px;
          &.rule-right-inview {
            padding: 32px;
            padding-right: 64px;
          }
        }
      }
    }
  }
}
